<template>
	<div class="pilot-dashboard">
		<action-list
			entity="BookingSegment"
			:title="$tc('listHeaders.openAssignment',2)"
			:columns="[
				{name:'id',header:$t('terms.action')},
				{name:'date',header:$t('listHeaders.startDate')},
				{name:'startHour',header:$t('listHeaders.fromTo')},
				{name:'booking',header:$t('listHeaders.address')},
			]"
			:staticParameters="openParameters"
			:show-custom-buttons="true"
			:reload-trigger="reload"
			firstColTdClasses="short"
		>
			<template v-slot:date="slotProps">
				{{slotProps.data.date | moment('DD-MM-YYYY')}}
			</template>
			<template v-slot:startHour="slotProps">
				{{slotProps.data.startHour < 10 ? '0'+slotProps.data.startHour : slotProps.data.startHour }} - {{slotProps.data.endHour < 10 ? '0'+slotProps.data.endHour : slotProps.data.endHour}}
			</template>
			<template v-slot:booking="slotProps">
				{{slotProps.data.booking.address}}
			</template>
			<template v-slot:id="slotProps">
				<small-button :text="$t('terms.signUp')" :loader-active="signingUp.indexOf(slotProps.data.id) !== -1" @click.native="signUp(slotProps.data)" />
			</template>
		</action-list>
		<action-list
			entity="BookingSegment"
			:title="$tc('listFields.upcomingAssignment',2)"
			:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.startDate')},
				{name:'startHour',header:$t('listHeaders.fromTo')},
				{name:'booking',header:$t('listHeaders.address')},
			]"
			:staticParameters="{
				explode: 'booking:address',
				status_equals: [4,5],
				pilot_equals: user,
				'order[date]': 'asc',
				'order[stateHour]': 'asc'
			}"
			:reload-trigger="reload"
			:item-link="{
				route: 'pilot-check-in-out',
				parameters: {
					id: 'id'
				}
			}"
		>
			<template v-slot:date="slotProps">
				{{slotProps.data.date | moment('DD-MM-YYYY')}}
			</template>
			<template v-slot:startHour="slotProps">
				{{slotProps.data.startHour < 10 ? '0'+slotProps.data.startHour : slotProps.data.startHour }} - {{slotProps.data.endHour < 10 ? '0'+slotProps.data.endHour : slotProps.data.endHour}}
			</template>
			<template v-slot:booking="slotProps">
				{{slotProps.data.booking.address}}
			</template>
		</action-list>
		<action-list
			entity="BookingSegment"
			:title="$tc('listHeaders.previousAssignment',2)"
			:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.startDate')},
				{name:'startHour',header:$t('listHeaders.fromTo')},
				{name:'booking',header:$t('listHeaders.address')},
			]"
			:staticParameters="{
				explode: 'booking:address',
				status_equals: 6,
				pilot_equals: user,
				'order[date]': 'asc',
				'order[stateHour]': 'asc'
			}"
		>
			<template v-slot:date="slotProps">
				{{slotProps.data.date | moment('DD-MM-YYYY')}}
			</template>
			<template v-slot:startHour="slotProps">
				{{slotProps.data.startHour < 10 ? '0'+slotProps.data.startHour : slotProps.data.startHour }} - {{slotProps.data.endHour < 10 ? '0'+slotProps.data.endHour : slotProps.data.endHour}}
			</template>
			<template v-slot:booking="slotProps">
				{{slotProps.data.booking.address}}
			</template>
		</action-list>
	</div>
</template>

<script>
	import ActionList from "@/components/list/ActionList";
	import {mapState} from "vuex";
	import SmallButton from "@/components/buttons/SmallButton";
	export default {
		name: "PilotDashboard",
		components: {SmallButton, ActionList},
		data(){
			return {
				signingUp: [],
				reload: 0
			}
		},
		computed: {
			...mapState('concrete', {
				user: 'user',
				apiKey: 'apiKey'
			}),
			openParameters(){
				const parameters = {
					explode: 'booking:address',
					status_equals: 3,
					'order[date]': 'asc',
					'order[stateHour]': 'asc'
				};
				
				const booking = this.findGetParameter('upcommingBooking');
				if(booking !== null)
				{
					parameters['booking'] = booking;
				}
				return parameters;
			}
		},
		methods: {
			findGetParameter(parameterName) {
				let result = null,
					tmp = [];
				location.search
				.substr(1)
				.split("&")
				.forEach(function (item) {
					tmp = item.split("=");
					if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
				});
				return result;
			},
			signUp(point)
			{
				if(this.signingUp.indexOf(point.id) === -1)
				{
					this.$swal.fire({
						icon: 'question',
						title: this.$t("alerts.pilotSignUpTitle"),
						text: this.$t("alerts.pilotSignUpDescription",{id:point.id}),
						showCancelButton: true
					}).then((result) => {
						if(result.value === true)
						{
							this.signingUp.push(point.id);
							this.$concreteApi.get(this.apiKey,'/booking_segments/'+point.id+'/sign_up')
							.then(response => {
								if(typeof response.result !== 'undefined' && response.result === true)
								{
									this.$swal.fire({
										icon: 'success',
										title: this.$t("terms.success"),
										text: this.$t("alerts.pilotSignUpSuccess"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									}).then((response) => {
										this.reload++;
									});
								}
								else if(typeof response.reason !== 'undefined')
								{
									this.$swal.fire({
										icon: 'error',
										title: this.$t("terms.error"),
										text: this.$t("errors."+response.reason),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									});
								}
								else
								{
									this.fireGenericError();
								}
							})
							.catch(error => {
								console.log(error);
								this.fireGenericError();
							})
							.then(() => {
								const index = this.signingUp.indexOf(point.id);
								if(index !== -1)
									this.signingUp.splice(index,1);
							},
							() => {
								const index = this.signingUp.indexOf(point.id);
								if(index !== -1)
									this.signingUp.splice(index,1);
							})
						}
					});
					
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

</style>
