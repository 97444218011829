<template>
    <label class="form-check py-1 radio-button">{{label}}
        <input type="radio" :checked="this.checked" :name="name">
        <span class="checkmark"></span>
    </label>
</template>

<script>
    export default {
        name: "RadioButton",
        props: {
            label: {
                type: String,
                default: ""
            },
            checked: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: 'radio'
            }
        }
    }
</script>

<style lang="scss" scoped>
	.form-check {
		display: block;
		position: relative;
		padding-left: 50px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 0.875rem;;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default radio button */
	.form-check input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}

	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 4px;
		left: 4px;
		height: 20px;
		width: 20px;
		background-color: $lightGray;
		border-radius: 50%;
	}

	/* On mouse-over, add a grey background color */
	.form-check:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the radio button is checked, add a blue background */
	.form-check input:checked ~ .checkmark {
		background-color: $blue;
	}

	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
	content: "";
		position: absolute;
	}

	/* Show the indicator (dot/circle) when checked */
	.form-check input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the indicator (dot/circle) */
	.form-check .checkmark:after {
		top: 6px;
		left: 6px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: $white;
	}
</style>