<template>
	<div class="hauling-assignment-part" :class="{'is-garage': isGarage}">
		<div class="content">
			<template v-if="isGarage">
				<garage-icon :width="58" class="garage light-blue" />
				<div class="garage-address light-gray"><div class="address text-part">{{addressDisplay}}</div></div>
				<div class="clearfix"></div>
			</template>
			<template v-else>
				<div v-if="typeDisplay !== false" class="light-gray text-part">{{typeDisplay}}</div>
				<div class="time light-blue text-part">{{timeDisplay | moment("HH:mm")}}</div>
				<div class="light-gray text-part">{{addressDisplay}}</div>
			</template>
		</div>
		
		<div class="info-icon-wrapper" @click="showSiteInfo">
			<info-icon :width="24"/>
		</div>
	</div>
</template>

<script>
	import GarageIcon from "@/components/icons/GarageIcon";
	import InfoIcon from "@/components/icons/InfoIcon";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	export default {
		name: "HaulingAssignmentPart",
		components: {InfoIcon, GarageIcon},
		mixins: [dataConversionMethods],
		computed: {
			typeDisplayMap(){
				return {
					0: {
						to: this.$t('terms.deliveryLatest')
					},
					1: {
						from: this.$t('terms.pickup')
					},
					2: {
						from: this.$t('terms.pickup'),
						to: this.$t('terms.deliveryLatest')
					}
				}
			},
			isGarage(){
				if(this.assignment.type === 1)
					return false;
				
				return !(this.direction === 'from' && this.assignment.type === 2 || this.direction === 'to' && this.assignment.type === 0)
			},
			typeDisplay(){
				return typeof this.typeDisplayMap[this.assignment.type][this.direction] !== 'undefined' ? this.typeDisplayMap[this.assignment.type][this.direction] : false ;
			},
			addressDisplay(){
				return this.direction === 'from' ? this.assignment.fromAddress : this.assignment.toAddress ;
			},
			timeDisplay(){
				return this.direction === 'from' ? this.assignment.fromTime : this.assignment.toTime ;
			},
			segment(){
				return this.direction === 'from' ? this.assignment.fromBookingSegment : this.assignment.toBookingSegment ;
			},
		},
		methods: {
			showSiteInfo(){
				const data = this.builtAssignmentDetailsObject(this.assignment,this.direction);
				let html = '<strong>'+this.$t('terms.assignment')+':</strong><br>'+data.assignment+'<br><br>';
				
				if(typeof data.date !== 'undefined')
					html += '<strong>'+this.$t('terms.date')+':</strong> '+data.date+'<br>';
				
				html += '<strong>'+this.$tc('terms.machine',1)+':</strong> '+data.machine+'<br><br>';
				
				if(typeof data.openingHours !== 'undefined')
					html += '<strong>'+this.$t('hauler.constructionSiteOpeningHours')+':</strong><br>'+data.openingHours+'<br><br>';
				
				if(typeof data.siteInfo !== 'undefined' && data.siteInfo !== '')
					html += '<strong>'+this.$t('hauler.additionalConstructionSiteInfo')+':</strong><br>'+data.siteInfo+'<br><br>';
				
				if(typeof data.pilot === 'object')
					html += '<strong>'+this.$t('roles.PILOT')+':</strong><br>'+this.$t('labels.name')+': '+data.pilot.name+'<br>'+this.$t('labels.phone')+': '+data.pilot.mobile+'<br><br>';
				
				this.$swal({
					icon:'info',
					html: html,
				})
			}
		},
		props: {
			direction:{
				type: String,
				required: true
			},
			assignment:{
				type: Object
			}
		}
	};
</script>

<style lang="scss" scoped>
	.hauling-assignment-part{
		background-color: #E1EDF4;
		border: 1px solid #A3B5BF;
		border-radius: 4px;
		box-shadow: 0 17px 20px 0 rgba(0,0,0,0.07);
		padding: 20px 46px 20px 23px;
		position: relative;
		&.is-garage{
			padding: 32px 46px 32px 23px;
		}
		.light-gray{
			color:#767676;
		}
		.light-blue{
			color: #3E474C;
		}
		.info-icon-wrapper{
			position: absolute;
			top:13px;
			right: 14px;
		}
		.time{
			font-size: 40px;
			font-weight: bold;
			line-height: 40px;
		}
		.garage{
			float: left;
			margin-right: 15px;
		}
		.text-part{
			max-width: 250px;
		}
		.garage-address{
			float: left;
			height: 58px;
			position: relative;
			.address{
				top:50%;
				position: relative;
				transform: translateY(-50%);
			}
		}
	}
</style>
