<template>
	<div class="entrepreneur-dashboard">
		<action-list
			entity="Booking"
			:title="$tc('terms.booking',2)"
			:columns="[
				{name:'id',header:'#ID'},
				{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
				{name:'dateAndTimeSlotDrivers',header:this.$t('listHeaders.dateAndTimeSlotDrivers')},
				{name:'casenumber',header:$t('listHeaders.case')},
				{name:'address',header:$t('listHeaders.address')},
				{name:'issuer',header:$t('listHeaders.createdBy')},
				{name:'status',header:$t('listHeaders.status')},
			]"
			:staticParameters="{
				explode: 'user:name,bookingSegment:date,bookingSegment:pilot,bookingSegment:startHour,bookingSegment:endHour,bookingSegmentReservation:slot,slot:date,slot:startHour,slot:endHour',
				'order[createdAt]':'desc'
			}"
			:item-link="{
				route: 'booking-view',
				parameters: {
					id: 'id'
				}
			}"
			:show-delete="isGranted(permissions.BOOKING.OWN.DELETE,viewingEntity)"
			:row-state-filters="{
				completed: {property: 'status', value: [2,3], compare: 'in'},
				'no-delete': {property: 'issuer.id', value: user, compare: '!='},
			}"
			:create="{
				link: createLink
			}"
			:deleteHeadline="this.$t('terms.cancel')"
		>
			<template v-slot:issuer="slotProps">
				{{slotProps.data.issuer.name}}
			</template>
			<template v-slot:status="slotProps">
				{{formatBookingStatus(slotProps.data.status)}}
			</template>
			<template v-slot:dateAndTimeSlots="slotProps">
				<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentTime(segment)}}</p>
				<p v-for="(reservation,index) in slotProps.data.bookingSegmentReservations" :key="reservation['@id']" class="list-internal-line reservation">{{formatSegmentTime(reservation.slot)}} (R)</p>
			</template>
			<template v-slot:dateAndTimeSlotDrivers="slotProps">
				<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentPilot(segment)}}</p>
				<p v-for="(reservation,index) in slotProps.data.bookingSegmentReservations" :key="reservation['@id']" class="list-internal-line reservation">-</p>
			</template>
		</action-list>
	</div>
</template>

<script>
	import ActionList from "@/components/list/ActionList";
	import isGranted from "@/mixin/isGranted";
	import permissions from "@/permissions";
	import {mapGetters, mapState} from "vuex";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	export default {
		name: "EntrepreneurDashboard",
		mixins: [isGranted,dataConversionMethods],
		components: {ActionList},
		data(){
			return {
				permissions: permissions,
				createLinkRaw: process.env.VUE_APP_CONCRETE_BOOKING_URL,
			}
		},
		computed: {
			...mapState('concrete', {
				viewingEntity: 'viewingEntity',
				apiKey: 'apiKey',
				user: 'user'
			}),
			...mapGetters('concrete', {
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			}),
			createLink(){
				return this.createLinkRaw+this.$i18n.locale+'?userToken='+this.apiKey
			}
		}
	};
</script>

<style scoped>

</style>
