var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-dashboard"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3"},[_c('div',{staticClass:"h3 mb-0"},[_vm._v(_vm._s(_vm.$t('forms.billingDetails')))]),_c('div',{staticClass:"btn-toolbar mb-2 mb-md-0"},[_c('small-button',{attrs:{"text":_vm.$t('terms.edit')},nativeOn:{"click":function($event){return _vm.goTo({name: 'company-edit',params:{id:_vm.viewingEntityIRIParts.id}})}}})],1)]),_c('add-edit-company-form',{attrs:{"company":_vm.viewingEntityIRIParts.id,"disabled":true,"show-credit-card-add-form":true}}),_c('action-list',{attrs:{"entity":"User","title":_vm.$tc('terms.employee',2),"columns":[
				{name:'id',header:'#ID'},
				{name:'name',header:_vm.$t('listHeaders.name')},
				{name:'email',header:_vm.$t('listHeaders.email')},
				{name:'bookings',header:_vm.$tc('terms.booking',2)},
				{name:'createdAt',header:_vm.$t('listHeaders.createdAt')} ],"show-delete":_vm.isGranted(_vm.permissions.USER.DELETE,_vm.viewingEntity),"item-link":{
				route: 'user-view',
				parameters: {
					id: 'id'
				}
			},"create":{
				route: 'user-add',
				permissions: [_vm.permissions.SCREEN.USER,_vm.permissions.USER.CREATE]
			}},scopedSlots:_vm._u([{key:"createdAt",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("moment")(slotProps.data.createdAt,"DD-MM-YYYY"))+" ")]}},{key:"bookings",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.bookings.length)+" ")]}}])}),_c('action-list',{attrs:{"entity":"Booking","title":_vm.$tc('terms.booking',2),"columns":[
				{name:'id',header:'#ID'},
				{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
				{name:'dateAndTimeSlotDrivers',header:this.$t('listHeaders.dateAndTimeSlotDrivers')},
				{name:'casenumber',header:_vm.$t('listHeaders.case')},
				{name:'address',header:_vm.$t('listHeaders.address')},
				{name:'issuer',header:_vm.$t('listHeaders.createdBy')},
				{name:'status',header:_vm.$t('listHeaders.status')} ],"staticParameters":{
				explode: 'user:name,bookingSegment:date,bookingSegment:pilot,bookingSegment:startHour,bookingSegment:endHour,bookingSegmentReservation:slot,slot:date,slot:startHour,slot:endHour',
				'order[createdAt]': 'desc',
			},"item-link":{
				route: 'booking-view',
				parameters: {
					id: 'id'
				}
			},"show-delete":_vm.isGranted(_vm.permissions.BOOKING.DELETE,_vm.viewingEntity),"row-state-filters":{
				completed: {property: 'status', value: [2,3], compare: 'in'}
			},"create":{
				link: _vm.createLink
			},"deleteHeadline":this.$t('terms.cancel')},scopedSlots:_vm._u([{key:"issuer",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.issuer.name)+" ")]}},{key:"status",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.formatBookingStatus(slotProps.data.status))+" ")]}},{key:"dateAndTimeSlots",fn:function(slotProps){return [_vm._l((slotProps.data.bookingSegments),function(segment,index){return _c('p',{key:segment['@id'],staticClass:"list-internal-line"},[_vm._v(_vm._s(_vm.formatSegmentTime(segment)))])}),_vm._l((slotProps.data.bookingSegmentReservations),function(reservation,index){return _c('p',{key:reservation['@id'],staticClass:"list-internal-line reservation"},[_vm._v(_vm._s(_vm.formatSegmentTime(reservation.slot))+" (R)")])})]}},{key:"dateAndTimeSlotDrivers",fn:function(slotProps){return [_vm._l((slotProps.data.bookingSegments),function(segment,index){return _c('p',{key:segment['@id'],staticClass:"list-internal-line"},[_vm._v(_vm._s(_vm.formatSegmentPilot(segment)))])}),_vm._l((slotProps.data.bookingSegmentReservations),function(reservation,index){return _c('p',{key:reservation['@id'],staticClass:"list-internal-line reservation"},[_vm._v("-")])})]}}])}),_c('action-list',{attrs:{"entity":"Invoice","title":_vm.$tc('terms.invoice',2),"columns":[
				{name:'id',header:'#ID'},
				{name:'type',header:_vm.$t('listHeaders.type')},
				{name:'casenumber',header:_vm.$t('listHeaders.case')},
				{name:'price',header:_vm.$t('listHeaders.price')},
				{name:'issuer',header:_vm.$t('listHeaders.booker')},
				{name:'status',header:_vm.$t('listHeaders.status')} ],"staticParameters":{
				explode: 'user:name,booking:issuer,booking:casenumber',
				'order[createdAt]': 'desc',
			},"row-state-filters":{
				completed: {property: 'status', value: [3], compare: 'in'}
			},"show-custom-buttons":true},scopedSlots:_vm._u([{key:"issuer",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.booking.issuer.name)+" ")]}},{key:"price",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.amountFormatted)+" ")]}},{key:"status",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.formatInvoiceStatus(slotProps.data.status,slotProps.data.type))+" ")]}},{key:"type",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.formatInvoiceType(slotProps.data.type))+" ")]}},{key:"buttons",fn:function(slotProps){return [_c('small-button',{class:{'hide-completed': slotProps.data.status === 3},attrs:{"text":_vm.$t('terms.pdf'),"loader-active":_vm.downloading.indexOf(slotProps.data.id) !== -1},nativeOn:{"click":function($event){return _vm.downloadPdf(slotProps.data)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }