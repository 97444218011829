<template>
	<div class="company-dashboard">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t('forms.billingDetails')}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$t('terms.edit')" @click.native="goTo({name: 'company-edit',params:{id:viewingEntityIRIParts.id}})" />
			</div>
		</div>
<!--			@deprecated Card system is deprecated since version 2-->
<!--		<div v-if="company.hasValidCard === false" class="inline-alert">
			<span v-html="$t('alerts.noValidCard')"></span>
		</div>-->
		<add-edit-company-form :company="viewingEntityIRIParts.id" :disabled = true :show-credit-card-add-form="true" />
		<action-list
			entity="User"
			:title="$tc('terms.employee',2)"
			:columns="[
				{name:'id',header:'#ID'},
				{name:'name',header:$t('listHeaders.name')},
				{name:'email',header:$t('listHeaders.email')},
				{name:'bookings',header:$tc('terms.booking',2)},
				{name:'createdAt',header:$t('listHeaders.createdAt')},
			]"
			:show-delete="isGranted(permissions.USER.DELETE,viewingEntity)"
			:item-link="{
				route: 'user-view',
				parameters: {
					id: 'id'
				}
			}"
			:create="{
				route: 'user-add',
				permissions: [permissions.SCREEN.USER,permissions.USER.CREATE]
			}"
		>
			<template v-slot:createdAt="slotProps">
				{{slotProps.data.createdAt | moment("DD-MM-YYYY")}}
			</template>
			<template v-slot:bookings="slotProps">
				{{slotProps.data.bookings.length}}
			</template>
		</action-list>
		<action-list
			entity="Booking"
			:title="$tc('terms.booking',2)"
			:columns="[
				{name:'id',header:'#ID'},
				{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
				{name:'dateAndTimeSlotDrivers',header:this.$t('listHeaders.dateAndTimeSlotDrivers')},
				{name:'casenumber',header:$t('listHeaders.case')},
				{name:'address',header:$t('listHeaders.address')},
				{name:'issuer',header:$t('listHeaders.createdBy')},
				{name:'status',header:$t('listHeaders.status')},
			]"
			:staticParameters="{
				explode: 'user:name,bookingSegment:date,bookingSegment:pilot,bookingSegment:startHour,bookingSegment:endHour,bookingSegmentReservation:slot,slot:date,slot:startHour,slot:endHour',
				'order[createdAt]': 'desc',
			}"
			:item-link="{
				route: 'booking-view',
				parameters: {
					id: 'id'
				}
			}"
			:show-delete="isGranted(permissions.BOOKING.DELETE,viewingEntity)"
			:row-state-filters="{
				completed: {property: 'status', value: [2,3], compare: 'in'}
			}"
			:create="{
				link: createLink
			}"
			:deleteHeadline="this.$t('terms.cancel')"
		>
			<template v-slot:issuer="slotProps">
				{{slotProps.data.issuer.name}}
			</template>
			<template v-slot:status="slotProps">
				{{formatBookingStatus(slotProps.data.status)}}
			</template>
			<template v-slot:dateAndTimeSlots="slotProps">
				<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentTime(segment)}}</p>
				<p v-for="(reservation,index) in slotProps.data.bookingSegmentReservations" :key="reservation['@id']" class="list-internal-line reservation">{{formatSegmentTime(reservation.slot)}} (R)</p>
			</template>
			<template v-slot:dateAndTimeSlotDrivers="slotProps">
				<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentPilot(segment)}}</p>
				<p v-for="(reservation,index) in slotProps.data.bookingSegmentReservations" :key="reservation['@id']" class="list-internal-line reservation">-</p>
			</template>
		</action-list>
		<action-list
			entity="Invoice"
			:title="$tc('terms.invoice',2)"
			:columns="[
				{name:'id',header:'#ID'},
				{name:'type',header:$t('listHeaders.type')},
				{name:'casenumber',header:$t('listHeaders.case')},
				{name:'price',header:$t('listHeaders.price')},
				{name:'issuer',header:$t('listHeaders.booker')},
				{name:'status',header:$t('listHeaders.status')},
			]"
			:staticParameters="{
				explode: 'user:name,booking:issuer,booking:casenumber',
				'order[createdAt]': 'desc',
			}"
			:row-state-filters="{
				completed: {property: 'status', value: [3], compare: 'in'}
			}"
			:show-custom-buttons="true"
		>
			<template v-slot:issuer="slotProps">
				{{slotProps.data.booking.issuer.name}}
			</template>
			<template v-slot:price="slotProps">
				{{slotProps.data.amountFormatted}}
			</template>
			<template v-slot:status="slotProps">
				{{formatInvoiceStatus(slotProps.data.status,slotProps.data.type)}}
			</template>
			<template v-slot:type="slotProps">
				{{formatInvoiceType(slotProps.data.type)}}
			</template>
			<template v-slot:buttons="slotProps">
				<small-button :class="{'hide-completed': slotProps.data.status === 3}" :text="$t('terms.pdf')"  @click.native="downloadPdf(slotProps.data)" :loader-active="downloading.indexOf(slotProps.data.id) !== -1"  />
			</template>
		</action-list>
	</div>
</template>

<script>
	import ActionList from "@/components/list/ActionList";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	import isGranted from "@/mixin/isGranted";
	import permissions from "@/permissions";
	import {mapGetters, mapState} from "vuex";
	import AddEditCompanyForm from "@/components/forms/AddEditCompanyForm";
	import SmallButton from "@/components/buttons/SmallButton";
	import downloadMethods from "@/mixin/downloadMethods";
	export default {
		name: "CompanyDashboard",
		components: {SmallButton, AddEditCompanyForm, ActionList},
		mixins: [dataConversionMethods,isGranted, downloadMethods],
		data(){
			return {
				createLinkRaw: process.env.VUE_APP_CONCRETE_BOOKING_URL,
				permissions: permissions,
			}
		},
		computed: {
			...mapState('concrete', {
				viewingEntity: 'viewingEntity',
				apiKey: 'apiKey',
				locale: 'locale',
				companies: 'companies'
			}),
			...mapGetters('concrete', {
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			}),
			company(){
				return this.companies[this.viewingEntityIRIParts.id]
			},
			createLink(){
				return this.createLinkRaw+this.locale+'?userToken='+this.apiKey
			}
		},
		methods: {
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
		}
	};
</script>

<style lang="scss" scoped>
	.inline-alert{
		background-color: rgba(255, 0, 0, 0.11);
		border-left: 3px solid red;
		padding: 5px 5px 5px 10px;
	}
</style>
