var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entrepreneur-dashboard"},[_c('action-list',{attrs:{"entity":"Booking","title":_vm.$tc('terms.booking',2),"columns":[
			{name:'id',header:'#ID'},
			{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
			{name:'dateAndTimeSlotDrivers',header:this.$t('listHeaders.dateAndTimeSlotDrivers')},
			{name:'casenumber',header:_vm.$t('listHeaders.case')},
			{name:'address',header:_vm.$t('listHeaders.address')},
			{name:'issuer',header:_vm.$t('listHeaders.createdBy')},
			{name:'status',header:_vm.$t('listHeaders.status')} ],"staticParameters":{
			explode: 'user:name,bookingSegment:date,bookingSegment:pilot,bookingSegment:startHour,bookingSegment:endHour,bookingSegmentReservation:slot,slot:date,slot:startHour,slot:endHour',
			'order[createdAt]':'desc'
		},"item-link":{
			route: 'booking-view',
			parameters: {
				id: 'id'
			}
		},"show-delete":_vm.isGranted(_vm.permissions.BOOKING.OWN.DELETE,_vm.viewingEntity),"row-state-filters":{
			completed: {property: 'status', value: [2,3], compare: 'in'},
			'no-delete': {property: 'issuer.id', value: _vm.user, compare: '!='},
		},"create":{
			link: _vm.createLink
		},"deleteHeadline":this.$t('terms.cancel')},scopedSlots:_vm._u([{key:"issuer",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.issuer.name)+" ")]}},{key:"status",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.formatBookingStatus(slotProps.data.status))+" ")]}},{key:"dateAndTimeSlots",fn:function(slotProps){return [_vm._l((slotProps.data.bookingSegments),function(segment,index){return _c('p',{key:segment['@id'],staticClass:"list-internal-line"},[_vm._v(_vm._s(_vm.formatSegmentTime(segment)))])}),_vm._l((slotProps.data.bookingSegmentReservations),function(reservation,index){return _c('p',{key:reservation['@id'],staticClass:"list-internal-line reservation"},[_vm._v(_vm._s(_vm.formatSegmentTime(reservation.slot))+" (R)")])})]}},{key:"dateAndTimeSlotDrivers",fn:function(slotProps){return [_vm._l((slotProps.data.bookingSegments),function(segment,index){return _c('p',{key:segment['@id'],staticClass:"list-internal-line"},[_vm._v(_vm._s(_vm.formatSegmentPilot(segment)))])}),_vm._l((slotProps.data.bookingSegmentReservations),function(reservation,index){return _c('p',{key:reservation['@id'],staticClass:"list-internal-line reservation"},[_vm._v("-")])})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }