<template>
    <form-container>
        <field-input :label='$t("labels.vatNumber")' id="cvr" />
        <field-input :label='$t("labels.company")' id="company" />
        <field-input :label='$t("labels.billingEmail")' id="billing-email" />
        <field-input :label='$t("labels.attPerson")'  id="att-person" />
        <field-input :label='$t("labels.billingAddress")'  id="billing-address"/>
        <field-input :label='$t("labels.postalZip")'  id="postal-zip" classes="col-4 col-md-2" />
        <field-input :label='$t("labels.city")'  id="city" classes="col-8 col-md-4" />
        <field-input :label='$t("labels.phone")'  id="Phone"/>
        <field-input :label='$t("labels.country")'  id="Country"/>
        <div class="col-md-12">
            <hr class="dashed-line mt-5">
            <div class="creditcard pb-3">
                <img src="@/assets/images/creditcard.svg">
                <span class="creditcard-label pl-4">{{$t("forms.creditCard")}}</span>
                <small-button class="add-card-button" :text='$t("forms.addCreditCard")' route="/admin" />
            </div>
            <div class="creditcard-selector">
                <radio-button :label='"4571-XXXX-XXXX-1728 ("+$t("terms.default")+")"' :checked="true" name='creditcard' />
                <radio-button label="4571-XXXX-XXXX-1799"  name='creditcard' />
            </div>
        </div>
    </form-container>
</template>

<script>
	import FormContainer from "@/components/container/FormContainer";
	import SmallButton from "@/components/buttons/SmallButton";
    import RadioButton from "@/components/inputs/RadioButton";
    import FieldInput from "@/components/inputs/FieldInput";

    export default {
        name: "PaymentForm",
        components: {FormContainer,SmallButton, RadioButton, FieldInput},
        props: {

        }
    }
</script>

<style lang="scss" scoped>
	.creditcard{
		display: flex;
		flex-direction: row;

		.creditcard-label{
			padding-top: 2px;
			font-size: 1.3125rem;
		}

		.add-card-button{
			margin-left: auto;
		}	
	}



	.dashed-line{
		background-image: url("~@/assets/images/dashed-line.svg");
		background-size: contain;
		border: 0;
		height: 1px;
		background-repeat: repeat-x;
	}
</style>