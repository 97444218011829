var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pilot-dashboard"},[_c('action-list',{attrs:{"entity":"BookingSegment","title":_vm.$tc('listHeaders.openAssignment',2),"columns":[
			{name:'id',header:_vm.$t('terms.action')},
			{name:'date',header:_vm.$t('listHeaders.startDate')},
			{name:'startHour',header:_vm.$t('listHeaders.fromTo')},
			{name:'booking',header:_vm.$t('listHeaders.address')} ],"staticParameters":_vm.openParameters,"show-custom-buttons":true,"reload-trigger":_vm.reload,"firstColTdClasses":"short"},scopedSlots:_vm._u([{key:"date",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("moment")(slotProps.data.date,'DD-MM-YYYY'))+" ")]}},{key:"startHour",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.startHour < 10 ? '0'+slotProps.data.startHour : slotProps.data.startHour)+" - "+_vm._s(slotProps.data.endHour < 10 ? '0'+slotProps.data.endHour : slotProps.data.endHour)+" ")]}},{key:"booking",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.booking.address)+" ")]}},{key:"id",fn:function(slotProps){return [_c('small-button',{attrs:{"text":_vm.$t('terms.signUp'),"loader-active":_vm.signingUp.indexOf(slotProps.data.id) !== -1},nativeOn:{"click":function($event){return _vm.signUp(slotProps.data)}}})]}}])}),_c('action-list',{attrs:{"entity":"BookingSegment","title":_vm.$tc('listFields.upcomingAssignment',2),"columns":[
			{name:'id',header:_vm.$t('listHeaders.id')},
			{name:'date',header:_vm.$t('listHeaders.startDate')},
			{name:'startHour',header:_vm.$t('listHeaders.fromTo')},
			{name:'booking',header:_vm.$t('listHeaders.address')} ],"staticParameters":{
			explode: 'booking:address',
			status_equals: [4,5],
			pilot_equals: _vm.user,
			'order[date]': 'asc',
			'order[stateHour]': 'asc'
		},"reload-trigger":_vm.reload,"item-link":{
			route: 'pilot-check-in-out',
			parameters: {
				id: 'id'
			}
		}},scopedSlots:_vm._u([{key:"date",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("moment")(slotProps.data.date,'DD-MM-YYYY'))+" ")]}},{key:"startHour",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.startHour < 10 ? '0'+slotProps.data.startHour : slotProps.data.startHour)+" - "+_vm._s(slotProps.data.endHour < 10 ? '0'+slotProps.data.endHour : slotProps.data.endHour)+" ")]}},{key:"booking",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.booking.address)+" ")]}}])}),_c('action-list',{attrs:{"entity":"BookingSegment","title":_vm.$tc('listHeaders.previousAssignment',2),"columns":[
			{name:'id',header:_vm.$t('listHeaders.id')},
			{name:'date',header:_vm.$t('listHeaders.startDate')},
			{name:'startHour',header:_vm.$t('listHeaders.fromTo')},
			{name:'booking',header:_vm.$t('listHeaders.address')} ],"staticParameters":{
			explode: 'booking:address',
			status_equals: 6,
			pilot_equals: _vm.user,
			'order[date]': 'asc',
			'order[stateHour]': 'asc'
		}},scopedSlots:_vm._u([{key:"date",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm._f("moment")(slotProps.data.date,'DD-MM-YYYY'))+" ")]}},{key:"startHour",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.startHour < 10 ? '0'+slotProps.data.startHour : slotProps.data.startHour)+" - "+_vm._s(slotProps.data.endHour < 10 ? '0'+slotProps.data.endHour : slotProps.data.endHour)+" ")]}},{key:"booking",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.data.booking.address)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }