<template>
	<div class="dot-icons" :class="{vertical: vertical,'vertical-on-mobile':verticalOnMobile}">
		<div v-for="index in number" :key="index" class="dot" :class="{last: index === number}"/>
		<div class="clearfix"></div>
	</div>
</template>

<script>
	export default {
		name: "DotIcons",
		props: {
			number: {
				type: Number,
				default: 1
			},
			vertical: {
				type: Boolean,
				default: false
			},
			verticalOnMobile: {
				type: Boolean,
				default: true
			}
		}
	};
</script>

<style lang="scss" scoped>
	.dot-icons{
		&.vertical{
			.dot{
				float: none;
				margin-right: 0;
				margin-bottom: 9px;
				&.last{
					margin-bottom: 0;
				}
			}
		}
		&.vertical-on-mobile{
			@media (max-width: 767px) {
				.dot{
					float: none;
					margin-right: 0;
					margin-bottom: 9px;
					&.last{
						margin-bottom: 0;
					}
				}
			}
		}
		.dot{
			height: 6px;
			width: 6px;
			margin-right: 9px;
			float: left;
			background-color: #D8D8D8;
			border: 1px solid #979797;
			border-radius: 50%;
			&.last{
				margin-right: 0;
			}
		}
	}
</style>
