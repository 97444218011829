<template>
	<div class="super-admin-dashboard">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">Super admin dashboard</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SuperAdminDashboard"
	};
</script>

<style scoped>

</style>
