<template>
	<div class="check-mark-icon" :style="iconStyle">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74"><g fill-rule="nonzero"><path d="M52 24h-4l-2 1-13 18-5-8-2-1h-4l-1 1 10 14a3 3 0 005 0l17-24-1-1z"/><path d="M37 0a37 37 0 100 74 37 37 0 000-74zm0 68a31 31 0 110-62 31 31 0 010 62z"/></g></svg>
	</div>
</template>

<script>
	export default {
		name: "CheckMarkIcon",
		computed: {
			iconStyle(){
				let style = 'width:'+this.width+'px;';
				if(this.inline)
					style += 'display:inline-block;';
				
				style += 'fill:'+this.color+';';
				
				
				return style;
			}
		},
		props: {
			width: {
				type: Number,
				default: 20
			},
			inline: {
				type: Boolean,
				default: false
			},
			color: {
				type: String,
				default: '#3E474C'
			}
		}
	};
</script>

<style scoped>

</style>
