<template>
	<div class="hauling-assignment" :class="{'has-assignment':showingId !== false}">
		<div v-if="isFetching" class="spinner-wrapper">
			<spinner />
		</div>
		<div v-else-if="showingId === false">
			<p v-if="typeof errorText !== 'undefined'">{{errorText}}</p>
			<p v-else>{{$t('hauler.noAssignments')}}</p>
		</div>
		<template v-else>
			<div v-if="showCard && assignmentDataObject !== false" class="assignment-card mb-4">
				<div class="card-part">
					<strong>{{$t('terms.assignment')}}:</strong>
					<div v-html="assignmentDataObject.assignment"/>
				</div>
				<div class="card-part">
					<template v-if="typeof assignmentDataObject.date !== 'undefined'"><strong>{{this.$t('terms.date')}}</strong> {{assignmentDataObject.date}}<br></template>
					<strong>{{$tc('terms.machine',1)}}:</strong> {{assignmentDataObject.machine}}
				</div>
				<div v-if="typeof assignmentDataObject.pilot === 'object'" class="card-part">
					<strong>{{$t('roles.PILOT')}}:</strong><br>
					{{$t('labels.name')}}: {{assignmentDataObject.pilot.name}}<br>
					{{$t('labels.phone')}}: {{assignmentDataObject.pilot.mobile}}
				</div>
				<div v-if="typeof assignmentDataObject.openingHours !== 'undefined'" class="card-part">
					<strong>{{$t('hauler.constructionSiteOpeningHours')}}:</strong><br>
					{{assignmentDataObject.openingHours}}
				</div>
				<div v-if="typeof assignmentDataObject.siteInfo !== 'undefined' && assignmentDataObject.siteInfo !== ''" class="card-part">
					<strong>{{$t('hauler.additionalConstructionSiteInfo')}}:</strong><br>
					{{assignmentDataObject.siteInfo}}
				</div>
			</div>
			<div class="assignment" :class="{vertical: forceVertical, 'interaction-enabled':showInteraction}">
				<hauling-assignment-part class="part" direction="from" :assignment="assignmentEntity" />
				<div v-if="!showInteraction" class="part">
					<dot-icons :number="8" :vertical="forceVertical" />
				</div>
				<template v-else>
					<div class="part">
						<dot-icons :number="3" :vertical="forceVertical"/>
					</div>
					<div class="part centered" :class="{disabled: !mayStart}">
						<standard-button :text="$t('hauler.onMyWay')" @click.native="startAssignment" :loader-active="isStarting" :show-right-icon="startDone" >
							<template v-slot:icon-right>
								<check-mark-icon color="white"/>
							</template>
						</standard-button>
						<p>{{$t('hauler.clickWhenMachineAttached')}}</p>
					</div>
					<div class="part">
						<dot-icons :number="3" :vertical="forceVertical"/>
					</div>
					<div class="part centered" :class="{disabled: !mayEnd}">
						<standard-button :text="$t('hauler.machineDelivered')" @click.native="endAssignment" :loader-active="isEnding" :show-right-icon="endDone" >
							<template v-slot:icon-right>
								<check-mark-icon color="white"/>
							</template>
						</standard-button>
						<p>{{$t('hauler.clickWhenMachineDelivered')}}</p>
					</div>
					<div class="part">
						<dot-icons :number="3" :vertical="forceVertical"/>
					</div>
				</template>
				<hauling-assignment-part class="part" direction="to" :assignment="assignmentEntity" />
				<template v-if="endDone">
					<div class="part">
						<dot-icons :number="3" :vertical="forceVertical"/>
					</div>
					<div class="part centered">
						<check-mark-icon class="finish-check pb-2" :width="74" />
						<p>{{$t('hauler.assignmentFinished')}}<br>{{$t('hauler.thanksAndGoodDay')}}</p>
					</div>
				</template>
				<div class="clearfix"></div>
			</div>
			<div v-if="showLink" class="links">
				<router-link :to="{name:'hauling-assignment-perform',params:{id:showingId,lang:$i18n.locale}}"><standard-button :text="$t('hauler.goToAssignment')" /></router-link>
			</div>
		</template>
	</div>
</template>

<script>
	import DotIcons from "@/components/icons/DotIcons";
	import HaulingAssignmentPart from "@/components/modules/HaulingAssignmentPart";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState} from 'vuex';
	import StandardButton from "@/components/buttons/StandardButton";
	import {extractIdFromIri} from "@/helperFunctions";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	import CheckMarkIcon from "@/components/icons/CheckMarkIcon";
	export default {
		name: "HaulingAssignment",
		components: {CheckMarkIcon, StandardButton, Spinner, HaulingAssignmentPart, DotIcons},
		mixins: [dataConversionMethods],
		data(){
			return {
				isFetching: false,
				showingId: false,
				isStarting: false,
				isEnding: false
			}
		},
		computed: {
			...mapState('concrete',{
				haulingAssignments: 'haulingAssignments'
			}),
			assignmentEntity(){
				return this.showingId !== false && typeof this.haulingAssignments[this.showingId] !== 'undefined' ? this.haulingAssignments[this.showingId] : false ;
			},
			assignmentDataObject(){
				if(this.assignmentEntity === false && this.assignmentEntity.status !== 4)
					return false;
				
				const from = [0,1];
				const displayDirection = from.indexOf(this.assignmentEntity.status) !== -1 ? 'from' : 'to';
 				return this.builtAssignmentDetailsObject(this.assignmentEntity,displayDirection);
			},
			mayStart(){
				return this.assignmentEntity !== false && this.assignmentEntity.status === 1;
			},
			startDone(){
				return this.assignmentEntity.status === 2 || this.assignmentEntity.status === 3
			},
			mayEnd(){
				return this.assignmentEntity !== false && this.assignmentEntity.status === 2;
			},
			endDone(){
				return this.assignmentEntity.status === 3
			},
			assignmentId(){
				return (!isNaN(this.assignment)) ? this.assignment : extractIdFromIri(this.assignment);
			}
		},
		methods: {
			ensureAssignment(){
				this.showingId = false;
				this.isFetching = true;
				if(this.assignment === 'latest')
				{
					this.$store.dispatch('concrete/genericEntityRequest',{parameters:{prPage:1,status_equals: 1,'order[firstTime]': 'asc',explode: 'machine:name,bookingSegment:pilot,user:name,user:mobile,bookingSegment:booking,booking:siteOpen,booking:siteClose,booking:siteAlwaysOpen,booking:siteAdditionalInformation'},entity: {'@type':'HaulingAssignment'}})
					.then(result => {
						if(result['hydra:member'].length !== 0)
						{
							this.showingId = result['hydra:member'][0]['id'];
						}
						this.isFetching = false;
					})
					.catch(error => {
						this.isFetching = false;
						console.log(error);
					})
				}
				else
				{
					this.$store.dispatch('concrete/genericEntityRequest',{parameters:{explode: 'machine:name,bookingSegment:pilot,user:name,user:mobile,bookingSegment:booking,booking:siteOpen,booking:siteClose,booking:siteAlwaysOpen,booking:siteAdditionalInformation'},entity: {'@type':'HaulingAssignment','id':this.assignmentId}})
					.then(result => {
						if(typeof result['@type'] !== 'undefined' && result['@type'] === "HaulingAssignment")
						{
							this.showingId = result['id'];
						}
						this.isFetching = false;
					})
					.catch(error => {
						this.isFetching = false;
						console.log(error);
					})
				}
			},
			startAssignment(){
				if(!this.mayStart)
					return false;
				
				this.isStarting = true;
				this.getCurrentLatLng()
				.then(latLng => {
					console.log(latLng);
					this.$store.dispatch('concrete/startAssignment',{haulingAssignment:'/api/hauling_assignments/'+this.assignmentId,'latLng':latLng})
					.then(result => {
						console.log(result);
						this.isStarting = false;
					})
					.catch(error => {
						this.isStarting = false;
					})
				})
			},
			endAssignment(){
				if(!this.mayEnd)
					return false;
				
				this.isEnding = true;
				this.getCurrentLatLng()
				.then(latLng => {
					console.log(latLng);
					this.$store.dispatch('concrete/endAssignment',{haulingAssignment:'/api/hauling_assignments/'+this.assignmentId,'latLng':latLng})
					.then(result => {
						console.log(result);
						this.isEnding = false;
					})
					.catch(error => {
						this.isEnding = false;
					})
				})
			},
			getCurrentLatLng(){
				return new Promise((resolve, reject) => {
					let latLng = {
						lat: 0,
						lng: 0
					};
					if (navigator.geolocation)
					{
						navigator.geolocation.getCurrentPosition((position) =>
						{
							latLng.lat = position.coords.latitude;
							latLng.lng = position.coords.longitude;
							resolve(latLng)
						}, () =>
						{
							resolve(latLng)
						});
					}
					else
					{
						resolve(latLng)
					}
				});
			}
		},
		mounted()
		{
			this.ensureAssignment();
		},
		props: {
			assignment: {
				type: String|Number,
				required: true
			},
			showLink: {
				type: Boolean,
				default: false
			},
			showInteraction: {
				type: Boolean,
				default: false
			},
			showCard:{
				type: Boolean,
				default: false
			},
			errorText: {
				type: String
			},
			forceVertical: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	@mixin assignmentVertical() {
		max-width: 320px;
		margin: 0 auto;
		.part{
			float: none;
			min-height: auto;
			margin-right: 0;
			margin-bottom: 16px;
			&.hauling-assignment-part{
				width: auto;
			}
			&.last{
				margin-bottom: 0;
			}
			.dot-icons{
				top: 0;
				transform: none;
				width: 6px;
				margin: 0 auto;
			}
		}
	}
	.assignment-card{
		padding: 31px 25px;
		background-color: #F9F2D1;
		border:1px solid #BEB9A0;
		.card-part{
			margin-bottom: 20px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
	.assignment{
		.part{
			float: left;
			min-height: 124px;
			margin-right: 16px;
			position: relative;
			&.hauling-assignment-part{
				width: calc(50% - 80px);
			}
			&.centered{
				text-align: center;
			}
			&.last{
				margin-right: 0;
			}
			.standard-button{
				margin-bottom: 10px;
				width: 100%;
				height: 45px;
				line-height: 35px;
			}
			.dot-icons{
				position: relative;
				top:67px;
				transform: translateY(-50%);
			}
			.finish-check{
				margin: 0 auto;
			}
		}
		&.vertical{
			@include assignmentVertical;
		}
		@media (max-width: 767px) {
			@include assignmentVertical;
		}
	}
	
	.links{
		margin-top: 30px;
	}

</style>

<style lang="scss">
	.assignment{
		.part.disabled{
			opacity: 0.6;
			.standard-button{
				pointer-events: none;
			}
		}
	}
</style>
