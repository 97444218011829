<template>
	<div class="info-icon" :style="iconStyle">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill-rule="nonzero" fill="none"><g><circle fill="#2196F3" cx="12" cy="12" r="10.5"/><path fill="#FFF" d="M11 11h2v6h-2z"/><circle fill="#FFF" cx="12" cy="8.3" r="1.3"/></g><path d="M0 0h24v24H0z"/></g></svg>
	</div>
</template>

<script>
	export default {
		name: "InfoIcon",
		computed: {
			iconStyle(){
				let style = 'width:'+this.width+'px;';
				if(this.inline)
					style += 'display:inline-block;';
				
				return style;
			}
		},
		props: {
			width: {
				type: Number,
				default: 20
			},
			inline: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	.info-icon{
		cursor: pointer;
	}
</style>
