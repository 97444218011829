<template>
	<div class="hauler-dashboard">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3"><div class="h3 mb-0">{{$t('hauler.nextComingAssignment')}}</div></div>
		<hauling-assignment assignment="latest" show-link :error-text="$t('errors.noUpcomingAssignments')"/>
		<action-list
			entity="HaulingAssignment"
			:title="$tc('listHeaders.openAssignment',2)"
			:columns="[
				{name:'id',header:$t('terms.action')},
				{name:'date',header:$t('listHeaders.date')},
				{name:'assignment',header:$tc('listHeaders.assignment')},
				{name:'machine',header:$t('listHeaders.machine')},
				{name:'siteOpenHours',header:$t('listHeaders.siteOpenHours')},
				{name:'notes',header:$t('listHeaders.notes')},
			]"
			:staticParameters="{
					explode: 'machine:name,bookingSegment:booking,booking:siteOpen,booking:siteClose,booking:siteAlwaysOpen,booking:siteAdditionalInformation',
					status_equals: 0,
					'order[firstTime]': 'asc',
				}"
			:show-custom-buttons="true"
			:reload-trigger="reload"
			firstColTdClasses="short"
		>
			<template v-slot:date="slotProps">
				<template v-if="slotProps.data.type !== 0" >{{slotProps.data.fromTime | moment('DD-MM-YYYY')}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{slotProps.data.toTime | moment('DD-MM-YYYY')}}</template>
			</template>
			<template v-slot:assignment="slotProps">
				<template v-if="slotProps.data.type !== 0">{{$t('terms.pickup')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.fromAddress,time: $moment(slotProps.data.fromTime).format("HH:mm")})}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{$t('terms.delivery')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.toAddress,time: $moment(slotProps.data.toTime).format("HH:mm")})}}</template>
			</template>
			<template v-slot:machine="slotProps">
				{{slotProps.data.machine.name}}
			</template>
			<template v-slot:siteOpenHours="slotProps">
				<template v-if="slotProps.data.type !== 0">{{formatSiteOpenString(slotProps.data.fromBookingSegment.booking)}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{formatSiteOpenString(slotProps.data.toBookingSegment.booking)}}</template>
			</template>
			<template v-slot:notes="slotProps">
				<template v-if="slotProps.data.type !== 0"><info-icon v-if="slotProps.data.fromBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.fromBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2"><info-icon v-if="slotProps.data.toBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.toBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
			</template>
			<template v-slot:id="slotProps">
				<small-button :text="$t('terms.signUp')" :loader-active="signingUp.indexOf(slotProps.data.id) !== -1" @click.native="signUp(slotProps.data)" />
			</template>
		</action-list>
		
		<action-list
			entity="HaulingAssignment"
			:title="$tc('listFields.upcomingAssignment',2)"
			:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.date')},
				{name:'assignment',header:$tc('listHeaders.assignment')},
				{name:'machine',header:$t('listHeaders.machine')},
				{name:'siteOpenHours',header:$t('listHeaders.siteOpenHours')},
				{name:'notes',header:$t('listHeaders.notes')},
			]"
			:staticParameters="{
					explode: 'machine:name,bookingSegment:booking,booking:siteOpen,booking:siteClose,booking:siteAlwaysOpen,booking:siteAdditionalInformation',
					status_equals: [1,2],
					'order[firstTime]': 'asc',
				}"
			:item-link="{
				route:'hauling-assignment-perform',
				parameters: {
					id: 'id'
				}
			}"
			:show-custom-buttons="true"
			:reload-trigger="reload"
			firstColTdClasses="short"
		>
			<template v-slot:date="slotProps">
				<template v-if="slotProps.data.type !== 0" >{{slotProps.data.fromTime | moment('DD-MM-YYYY')}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{slotProps.data.toTime | moment('DD-MM-YYYY')}}</template>
			</template>
			<template v-slot:assignment="slotProps">
				<template v-if="slotProps.data.type !== 0">{{$t('terms.pickup')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.fromAddress,time: $moment(slotProps.data.fromTime).format("HH:mm")})}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{$t('terms.delivery')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.toAddress,time: $moment(slotProps.data.toTime).format("HH:mm")})}}</template>
			</template>
			<template v-slot:machine="slotProps">
				{{slotProps.data.machine.name}}
			</template>
			<template v-slot:siteOpenHours="slotProps">
				<template v-if="slotProps.data.type !== 0">{{formatSiteOpenString(slotProps.data.fromBookingSegment.booking)}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{formatSiteOpenString(slotProps.data.toBookingSegment.booking)}}</template>
			</template>
			<template v-slot:notes="slotProps">
				<template v-if="slotProps.data.type !== 0"><info-icon v-if="slotProps.data.fromBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.fromBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2"><info-icon v-if="slotProps.data.toBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.toBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
			</template>
		</action-list>
		
		<action-list
			entity="HaulingAssignment"
			:title="$tc('listHeaders.previousAssignment',2)"
			:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.date')},
				{name:'assignment',header:$tc('listHeaders.assignment')},
				{name:'machine',header:$t('listHeaders.machine')},
				{name:'siteOpenHours',header:$t('listHeaders.siteOpenHours')},
				{name:'notes',header:$t('listHeaders.notes')},
			]"
			:staticParameters="{
					explode: 'machine:name,bookingSegment:booking,booking:siteOpen,booking:siteClose,booking:siteAlwaysOpen,booking:siteAdditionalInformation',
					status_equals: [3],
					'order[firstTime]': 'asc',
				}"
			:item-link="{
				route:'hauling-assignment-perform',
				parameters: {
					id: 'id'
				}
			}"
			:row-state-filters="{
				completed: {property: 'status', value: [3], compare: 'in'}
			}"
			:show-custom-buttons="true"
			:reload-trigger="reload"
			firstColTdClasses="short"
		>
			<template v-slot:date="slotProps">
				<template v-if="slotProps.data.type !== 0" >{{slotProps.data.fromTime | moment('DD-MM-YYYY')}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{slotProps.data.toTime | moment('DD-MM-YYYY')}}</template>
			</template>
			<template v-slot:assignment="slotProps">
				<template v-if="slotProps.data.type !== 0">{{$t('terms.pickup')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.fromAddress,time: $moment(slotProps.data.fromTime).format("HH:mm")})}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{$t('terms.delivery')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.toAddress,time: $moment(slotProps.data.toTime).format("HH:mm")})}}</template>
			</template>
			<template v-slot:machine="slotProps">
				{{slotProps.data.machine.name}}
			</template>
			<template v-slot:siteOpenHours="slotProps">
				<template v-if="slotProps.data.type !== 0">{{formatSiteOpenString(slotProps.data.fromBookingSegment.booking)}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{formatSiteOpenString(slotProps.data.toBookingSegment.booking)}}</template>
			</template>
			<template v-slot:notes="slotProps">
				<template v-if="slotProps.data.type !== 0"><info-icon v-if="slotProps.data.fromBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.fromBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2"><info-icon v-if="slotProps.data.toBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.toBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
			</template>
		</action-list>
		
		<action-list
			entity="HaulingAssignment"
			:title="$tc('listHeaders.cancelledAssignments',2)"
			:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.date')},
				{name:'assignment',header:$tc('listHeaders.assignment')},
				{name:'machine',header:$t('listHeaders.machine')},
				{name:'siteOpenHours',header:$t('listHeaders.siteOpenHours')},
				{name:'notes',header:$t('listHeaders.notes')},
			]"
			:staticParameters="{
					explode: 'machine:name,bookingSegment:booking,booking:siteOpen,booking:siteClose,booking:siteAlwaysOpen,booking:siteAdditionalInformation',
					status_equals: [3],
					'order[firstTime]': 'asc',
				}"
			:item-link="{
				route:'hauling-assignment-perform',
				parameters: {
					id: 'id'
				}
			}"
			:row-state-filters="{
				completed: {property: 'status', value: [4], compare: 'in'}
			}"
			:show-custom-buttons="true"
			:reload-trigger="reload"
			firstColTdClasses="short"
		>
			<template v-slot:date="slotProps">
				<template v-if="slotProps.data.type !== 0" >{{slotProps.data.fromTime | moment('DD-MM-YYYY')}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{slotProps.data.toTime | moment('DD-MM-YYYY')}}</template>
			</template>
			<template v-slot:assignment="slotProps">
				<template v-if="slotProps.data.type !== 0">{{$t('terms.pickup')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.fromAddress,time: $moment(slotProps.data.fromTime).format("HH:mm")})}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{$t('terms.delivery')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.toAddress,time: $moment(slotProps.data.toTime).format("HH:mm")})}}</template>
			</template>
			<template v-slot:machine="slotProps">
				{{slotProps.data.machine.name}}
			</template>
			<template v-slot:siteOpenHours="slotProps">
				<template v-if="slotProps.data.type !== 0">{{formatSiteOpenString(slotProps.data.fromBookingSegment.booking)}}</template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2">{{formatSiteOpenString(slotProps.data.toBookingSegment.booking)}}</template>
			</template>
			<template v-slot:notes="slotProps">
				<template v-if="slotProps.data.type !== 0"><info-icon v-if="slotProps.data.fromBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.fromBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
				<br v-if="slotProps.data.type === 1" />
				<template v-if="slotProps.data.type !== 2"><info-icon v-if="slotProps.data.toBookingSegment.booking.siteAdditionalInformation !== ''" inline @click.native="displayInfo(slotProps.data.toBookingSegment.booking.siteAdditionalInformation)"/><span v-else class="has-size" /></template>
			</template>
		</action-list>
	</div>
</template>

<script>
	import ActionList from "@/components/list/ActionList";
	import SmallButton from "@/components/buttons/SmallButton";
	import {mapState} from "vuex";
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	import InfoIcon from "@/components/icons/InfoIcon";
	import HaulingAssignment from "@/components/modules/HaulingAssignment";
	import StandardButton from "@/components/buttons/StandardButton";
	export default {
		name: "HaulerDashboard",
		components: {StandardButton, HaulingAssignment, InfoIcon, SmallButton, ActionList},
		mixins: [dataConversionMethods],
		data(){
			return {
				signingUp: [],
				reload: 0
			}
		},
		computed: {
			...mapState('concrete', {
				user: 'user',
				apiKey: 'apiKey'
			})
		},
		methods: {
			displayInfo(text){
				this.$swal.fire({
					icon: 'info',
					title: this.$t("terms.siteInfo"),
					text: text,
				});
			},
			signUp(point){
				console.log(point);
				if(this.signingUp.indexOf(point.id) === -1)
				{
					this.$swal.fire({
						icon: 'question',
						title: this.$t("alerts.pilotSignUpTitle"),
						text: this.$t("alerts.assignmentSignUpDescription",{id:point.id}),
						showCancelButton: true
					}).then((result) => {
						if(result.value === true)
						{
							this.signingUp.push(point.id);
							this.$concreteApi.get(this.apiKey,'/hauling_assignments/'+point.id+'/sign_up')
							.then(response => {
								if(typeof response.result !== 'undefined' && response.result === true)
								{
									this.$swal.fire({
										icon: 'success',
										title: this.$t("terms.success"),
										text: this.$t("alerts.assignmentSignUpSuccess"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									}).then((response) => {
										this.reload++;
									});
								}
								else if(typeof response.reason !== 'undefined')
								{
									this.$swal.fire({
										icon: 'error',
										title: this.$t("terms.error"),
										text: this.$t("errors."+response.reason),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									});
								}
								else
								{
									this.fireGenericError();
								}
							})
							.catch(error => {
								console.log(error);
								this.fireGenericError();
							})
							.then(() => {
									const index = this.signingUp.indexOf(point.id);
									if(index !== -1)
										this.signingUp.splice(index,1);
								},
								() => {
									const index = this.signingUp.indexOf(point.id);
									if(index !== -1)
										this.signingUp.splice(index,1);
								})
						}
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.hauling-assignment.has-assignment{
		padding-bottom: 50px;
		border-bottom: 1px solid #979797;
		margin-bottom: 30px;
	}
</style>
