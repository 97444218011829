<template>
	<div class="garage-icon" :style="iconStyle">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58"><g fill="#3E474C" fill-rule="nonzero"><path d="M4 51v4l2 2h3c2 0 3-1 3-2v-3h34v3c0 1 1 2 3 2h3l3-2v-4l2-5v-8c0-3-1-5-3-6l-4-11c-1-4-4-6-8-6H16c-4 0-7 2-8 6L4 32c-2 1-3 3-3 6v8c0 2 1 4 3 5zm7-5a4 4 0 110-9 4 4 0 010 9zm36 0a4 4 0 110-9 4 4 0 010 9zM16 20h26l3 2 3 10H10l3-10 3-2z"/><path d="M57 16v-6L30 1h-2L1 11v6L29 6l28 10z"/></g></svg>
	</div>
</template>

<script>
	export default {
		name: "GarageIcon",
		computed: {
			iconStyle(){
				let style = 'width:'+this.width+'px;';
				if(this.inline)
					style += 'display:inline-block;';
				
				return style;
			}
		},
		props: {
			width: {
				type: Number,
				default: 20
			},
			inline: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style scoped>

</style>
