<template>
	<div class="admin-dashboard">
		<company-dashboard v-if="viewingEntityPermissionGroups.indexOf(permissionGroups.COMPANY.key) !== -1" />
		<entrepreneur-dashboard v-else-if="viewingEntityPermissionGroups.indexOf(permissionGroups.ENTREPRENEUR.key) !== -1" />
		<district-chief-dashboard v-else-if="viewingEntityPermissionGroups.indexOf(permissionGroups.DISTRICT_MANAGER.key) !== -1" />
		<pilot-dashboard v-else-if="viewingEntityPermissionGroups.indexOf(permissionGroups.PILOT.key) !== -1" />
		<hauler-dashboard v-else-if="viewingEntityPermissionGroups.indexOf(permissionGroups.HAULER.key) !== -1" />
		<super-admin-dashboard v-else-if="hasGlobalSuperAdmin" />
	<!--	<h2>Testing</h2>
		<add-edit-company-form />
		<payment-form />
		<map-with-details :mapLatLng="{lat: 55.495972, lng: 9.473052}"
			:pathDataArray='[
				{
					coords:[
						[{lat: 55.495972, lng: 9.473052},
						{lat: 55.485972, lng: 9.463052},
						{lat: 55.495972, lng: 9.463052}],
					],
					options: {
						fillColor: "#00FF00",
						strokeColor: "#00FF00",
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillOpacity: 0.35
					}
				},
				{
					coords:[
						{lat: 55.485972, lng: 9.463052},{lat: 55.495972, lng: 9.473052}
					],
					options: {
						fillColor: "#FF0000",
						strokeColor: "#FF0000",
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillOpacity: 0.35
					}
				}
			]'
		>
			<p><strong>Created date</strong><br>12-02-2021</p>
			<p><strong>Status</strong><br>Ready</p>
			<p><strong>Casenumber</strong><br>K001</p>
			<p><strong>Location</strong><br>Københavnergade 22,<br>1200 København K</p>
			<button class="btn btn-secondary btn-lg">Cancel Booking</button>
		</map-with-details>
	-->
		<!--<q-r-scanner v-model="qrvalue" label="Code" error="" />-->
	</div>
</template>

<script>
	import MapWithDetails from "@/components/modules/MapWithDetails";
	import PaymentForm from "@/components/forms/PaymentForm";
	import AddEditCompanyForm from "@/components/forms/AddEditCompanyForm";
	import QRScanner from "@/components/modules/QRScanner";
	import {mapState, mapGetters, mapMutations} from "vuex";
	import CompanyDashboard from "@/components/dashboards/CompanyDashboard";
	import permissionGroups from "@/permissionGroups";
	import EntrepreneurDashboard from "@/components/dashboards/EntrepreneurDashboard";
	import PilotDashboard from "@/components/dashboards/PilotDashboard";
	import DistrictChiefDashboard from "@/components/dashboards/DistrictChiefDashboard";
	import SuperAdminDashboard from "@/components/dashboards/SuperAdminDashboard";
	import HaulerDashboard from "@/components/dashboards/HaulerDashboard";
	import {findGetParameter} from "@/helperFunctions";
	export default {
		name: "Dashboard",
		components: {
			HaulerDashboard,
			SuperAdminDashboard,
			DistrictChiefDashboard,
			PilotDashboard,
			EntrepreneurDashboard, CompanyDashboard, MapWithDetails, PaymentForm, AddEditCompanyForm,QRScanner},
		data(){
			return {
				qrvalue: ''
			}
		},
		computed: {
			...mapState('concrete', {
				viewingEntity: 'viewingEntity'
			}),
			...mapGetters('concrete', {
				viewingEntityPermissionGroups: 'viewingEntityPermissionGroups',
				hasGlobalSuperAdmin: 'hasGlobalSuperAdmin'
			}),
			permissionGroups(){
				return permissionGroups;
			}
		},
		mounted()
		{
			const cardAuth = findGetParameter('cardAuth');
			if(cardAuth === 'continue')
			{
				this.$swal.fire({
					icon: 'success',
					title: this.$t("terms.success"),
					text: this.$t("alerts.cardContinueSuccess"),
					confirmButtonText: this.$t("terms.ok"),
					confirmButtonColor: "#495760"
				})
			}
			else if(cardAuth === 'cancel')
			{
				this.$swal.fire({
					icon: 'error',
					title: this.$t("terms.error"),
					text: this.$t("alerts.cardCancelSuccess"),
					confirmButtonText: this.$t("terms.ok"),
					confirmButtonColor: "#495760"
				})
			}
			else if(cardAuth !== null)
			{
				this.fireGenericError();
			}
		}
	};
</script>

<style lang="scss" scoped>

	.admin-dashboard {
		margin-bottom: 100px;
	}

//Structure of pathdataarray for map with details component
/*:pathDataArray='[
	{
		coords:[
			{lat: 55.495972, lng: 9.473052},
			{lat: 55.485972, lng: 9.463052},
			{lat: 55.495972, lng: 9.463052}
		],
		options: {
			fillColor: "#00FF00",
			strokeColor: "#00FF00",
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0.35
		}
	},
	{
		coords:[
			{lat: 55.505972, lng: 9.483052},
			{lat: 55.515972, lng: 9.473052},
			{lat: 55.505972, lng: 9.473052}
		],
		options: {
			fillColor: "#FF0000",
			strokeColor: "#FF0000",
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillOpacity: 0.35
		}
	}
]'*/

</style>
