<template>
	<div class="district-chief-dashboard">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">District manager dashboard</div>
		</div>
		<pilot-dashboard />
	</div>
</template>

<script>
	import PilotDashboard from "@/components/dashboards/PilotDashboard";
	export default {
		name: "DistrictChiefDashboard",
		components: {PilotDashboard}
	};
</script>

<style scoped>

</style>
